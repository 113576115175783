import CookieConsentState from '#js/components/cookieConsentState'
import { ready } from '#js/components/utils'

/** Allow all cookies purposes. */
export function allowAll () {
  afterConsentSelection({
    analytics_storage: 'granted',
    ad_storage: 'granted'
  })
}

/** Deny all cookies purposes. */
export function denyAll () {
  afterConsentSelection({
    analytics_storage: 'denied',
    ad_storage: 'denied'
  })
}

/** Allow only the selected cookies purposes. */
export function allowSelected () {
  const consentState = {
    analytics_storage: document.getElementById('analytic-switch').checked ? 'granted' : 'denied',
    ad_storage: document.getElementById('marketing-switch').checked ? 'granted' : 'denied'
  }
  afterConsentSelection(consentState)
}

/**
 * Save the consent state and start the product tour.
 * @param {object} consentState The consent state.
 */
export function afterConsentSelection (consentState) {
  const modal = document.getElementById('cookie-modal')
  window.cookieConsentState.set(consentState, () => {
    if (modal) {
      modal.close()
    }
    document.dispatchEvent(new Event('product-tour'))
  })
  // set a cookie to remember if we have shown the modal in this session
  const expiryDate = new Date()
  expiryDate.setDate(expiryDate.getDate() + 1)
  document.cookie = `_reshow=;expires=${expiryDate};domain=${window.location.hostname};path=/;SameSite=Strict`
}

/** Check if the user has already given consent. */
export function checkConsentState () {
  const cookieModal = document.getElementById('cookie-modal')

  if (!cookieModal) {
    return
  }

  const reshow = document.cookie.split(';').some((item) => item.trim().startsWith('_reshow='))

  if (!window.cookieConsentState.isSet()) {
    cookieModal.showModal()
  } else if (!window.cookieConsentState.hasFullConsent() && !reshow) {
    cookieModal.showModal()
  }
}

ready(function () {
  window.cookieConsentState = new CookieConsentState()
  checkConsentState()
})

window.cookieConsentModal = {
  allowAll,
  allowSelected,
  denyAll
}
